import axios from 'axios'
import jwt_decode from 'jwt-decode'
import {
    IS_LOADING,
    CHAT_USER_LIST,
    SET_MESSAGES,
    CHAT_USER_PROFILE_INFO,
    SET_UNREAD_COUNT,
    TIPS_LIST,
    IS_MORE_MESSAGE_LOADING,
    LOAD_MORE_MESSAGE,
    SET_CHAT_SELECTED_ID,
    SET_CHAT_MODEL_ID,
    ADD_NEW_MESSAGE_IN_END,
    UPDATE_MESSAGE,
    DELETE_MESSAGE,
    TOGGLE_CHAT_TIP_POPUP,
    UPDATE_USER_AMOUNT_SPENT,
    UPDATE_USER_LAST_SEEN,
    UPDATE_USER_NICKNAME,
    IS_MESSAGES_LOADING,
    SET_IS_CHAT_SCREEN_OPEN,
    UPDATE_USER_DETAILS,
    IS_READ_MESSAGE_COUNT,
    SHOW_BOOTSTRAP_NAVBAR,
    SET_ROTATE_CHAT_IMAGE,
    UPDATE_USER_LAST_MESSAGE,
    SET_SEARCH_INPUT_VALUE,
    DELETE_MESSAGE_FROM_ALL,
    SET_ROTATE_CHAT_MASS_MESSAGE,
    DELETE_RESEND_MASS_MESSAGE,
    DELETE_SINGLE_RESEND_MASS_MESSAGE
} from './types'
import { getAppBaseUrl } from './api'
import store from './../store'
import { paymentResponseRecieved } from './ccbillActions'
import { removePayPerMessageCredit } from './authActions'
import { updateWalletAmount } from './cryptoPaymentActions'
import _ from 'lodash'
import { setSweetAlert, removeConfirmSweetAlert } from './sweetAlertActions'
import { googleAnalyticsTrackEvent } from '../utils/GoogleAnalyticsEvent'
import { setBlurLoading, stopBlurLoading } from './adminActions'
import { ROLE_MODEL, ROLE_CONTENT_MANAGER, ROLE_USER } from '../utils/constant'

const BASE_URL = getAppBaseUrl()

// Get User List
export const getUserList = (data) => async (dispatch) => {
    try {
        dispatch(setLoading())
        const username = !_.isEmpty(data.userName) ? data.userName.trim() : ''
        const res = await axios.get(`${BASE_URL}/api/chat/get_user_list?page_num=${data.pageNum}&sort_by=${data.sortBy}&user_name=${username}&sort_by_subscribers=${data.sortBySubscribers}&list_id=${data.list_id}`)
        res.data.isInfiniteScroll = data.isInfiniteScroll
        dispatch(setUserList(res.data))
    } catch (error) {
        const message = _.get(error, 'response.data.message', 'Error occurred while getting user list')
        dispatch(setSweetAlert({ description: message }))
    }
}

// Get Messages
export const getUserMessages = (data, isAdmin, callback) => dispatch => {
    dispatch(setLoading())
    let token = localStorage.getItem('AuthToken')
    if (isAdmin === undefined && token) {
        isAdmin = dispatch(getAdminValue(token))
    }
    dispatch({ type: SET_CHAT_SELECTED_ID, payload: data.selectedUserId })
    const url = (isAdmin === true) ? BASE_URL + `/api/chat/get_messages_admin?selected_model_id=${data.selectedModelId}&selected_user_id=${data.selectedUserId}&page_num=${data.pageNum}` : BASE_URL + `/api/chat/get_messages?selected_user_id=${data.selectedUserId}&page_num=${data.pageNum}`

    axios.get(url)
        .then(res => {
            const shouldHideInChat = _.get(res, 'data.should_hide_in_chat', false)
            if (shouldHideInChat === true) {
                callback(false)
                return
            }
            dispatch(setMessages(res.data, data.userId))
            let readMessageData = {
                userId: data.selectedUserId
            }

            if (isAdmin === true) {
                readMessageData.modelId = data.selectedModelId
            }

            if ([ROLE_MODEL, ROLE_CONTENT_MANAGER, ROLE_USER].includes(data.role)) {
                dispatch(readMessage(readMessageData, isAdmin))
            }
            if (callback) {
                callback(true)
            }
        })
}

export const getMessages = (data, isAdmin) => dispatch => {
    const { pageNum } = data
    dispatch(updateIsMessagesLoading(true))
    let token = localStorage.getItem('AuthToken')
    if (isAdmin === undefined && token) {
        isAdmin = dispatch(getAdminValue(token))
    }
    dispatch({ type: SET_CHAT_SELECTED_ID, payload: data.selectedUserId })
    const url = BASE_URL + `/api/chat/get_messages_admin?selected_model_id=${data.selectedModelId}&selected_user_id=${data.selectedUserId}&page_num=${data.pageNum}`

    axios.get(url)
        .then(res => {
            const shouldHideInChat = _.get(res, 'data.should_hide_in_chat', false)
            if (shouldHideInChat === true) {
                dispatch(updateIsMessagesLoading(false))
                return
            }
            dispatch(setMessages(res.data, data.selectedUserId))
            let readMessageData = {
                userId: data.selectedUserId
            }

            if (isAdmin === true) {
                readMessageData.modelId = data.selectedModelId
            }

            if (data.role !== 'admin') {
                dispatch(readMessage(readMessageData, isAdmin))
            }
            dispatch(updateIsMessagesLoading(false))
            const chatList = document.getElementById('message-list')
            if (pageNum === 1 && chatList) {
                chatList.scrollTop = chatList.scrollHeight
            }
        })
}

// Load more Messages
export const loadMore = (data, isAdmin, callback) => dispatch => {
    dispatch(setIsMoreMessagesLoading(true, data.user_id))
    let token = localStorage.getItem('AuthToken')
    if (isAdmin === undefined && token) {
        isAdmin = dispatch(getAdminValue(token))
    }
    const url = (isAdmin === true) ? BASE_URL + `/api/chat/get_messages_admin?selected_user_id=${data.userId}&selected_model_id=${data.modelId}&page_num=${data.pageNum}` : BASE_URL + `/api/chat/get_messages?selected_user_id=${data.userId}&page_num=${data.pageNum}`

    axios.get(url)
        .then(res => {
            dispatch(setMoreMessages(res.data, data.user_id))
            callback()
        })
}

// Load more Messages
export const loadMoreMessages = (data, isAdmin) => async (dispatch) => {
    try {
        dispatch(setIsMoreMessagesLoading(true, data.userId))
        let token = localStorage.getItem('AuthToken')
        if (isAdmin === undefined && token) {
            isAdmin = dispatch(getAdminValue(token))
        }

        const url = BASE_URL + `/api/chat/get_messages_admin?selected_user_id=${data.userId}&selected_model_id=${data.modelId}&page_num=${data.pageNum}`

        const res = await axios.get(url)
        dispatch(setMoreMessages(res.data, data.userId))
        return
    } catch (error) {
        const message = _.get(error, 'response.data.message', 'There was a problem loading messages.')
        const payload = { description: message }
        dispatch(setSweetAlert(payload))
        dispatch(setIsMoreMessagesLoading(false, data.userId))
        return
    }
}

// Get isAdmin value from token
const getAdminValue = (token) => () => {
    const decoded = jwt_decode(token)

    return decoded.isAdmin
}

// Send Message to all users
export const sendMessageToAll = (data, paginationData) => dispatch => {
    axios.post(BASE_URL + '/api/chat/send_message_to_all', data)
        .then(() => {
            dispatch(getUserList(paginationData))
            return alert('Message Sent to all users')
        })
}

// Read message
export const readMessage = (data, isAdmin) => dispatch => {
    const url = (isAdmin === true) ? BASE_URL + '/api/chat/read_message_model' : BASE_URL + '/api/chat/read_message_user'

    axios.post(url, data)
        .then(() => {
            dispatch(refreshMessageCount())
            dispatch(updateReadMessageCount({ userId: data.userId }))
        })
}

export const refreshMessageCount = () => dispatch => {
    axios.get(BASE_URL + '/api/users/get_unread_count')
        .then(res => {
            dispatch(setUnreadCount(res.data))
        })
}

window.skipLookup = {}

export const chatUserProfileInfoAction = (userId) => (dispatch) => {
    if (userId) {
        if (window.skipLookup[userId] !== true) {
            window.skipLookup[userId] = true
            axios.get(BASE_URL + `/api/chat/get_user_details?user_id=${userId}`)
                .then(res => {
                    dispatch(chatUserProfileInfo(res.data))
                })
        }
    }
}

export const updateUserChatNickname = (data) => dispatch => {
    if (data.userId !== undefined) {
        dispatch(updateUsersNickname(data))
    }
}


// Get booked slot data.
export const getBookedSlotData = (data, callback) => () => {
    if (data.userId !== undefined) {
        axios.get(BASE_URL + `/api/chat/get_user_booked_slot_data?user_id=${data.userId}&timezone=${data.timezone}`)
            .then(res => {
                callback(res.data)
            })
    }
}

// Get booked slot start time.
export const getNextMeetingTime = (data, callback) => () => {
    axios.post(BASE_URL + '/api/chat/get_next_meeting_time', data)
        .then(res => {
            callback(res.data)
        })
}

// send message
export const sendTextMessage = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/chat/send_message', data)
        .then(res => {
            if (res.data.wallet_balance !== false) {
                dispatch(updateWalletAmount(res.data.wallet_balance))
            }
            dispatch(removePayPerMessageCredit())
            if (data.type === 'text') {
                dispatch(updateMessageAction(res.data, data.userId, data._id))
            } else {
                dispatch(addNewMessageInEnd(res.data, data.userId))
            }
            callback(true)
            if (res.data.fromAdmin === false && res.data.is_pay_per_message === true) {
                const transaction_id = res.data.transactionId + '-' + res.data.senderId
                googleAnalyticsTrackEvent('purchase', transaction_id, res.data.amount, 'chat', 'pay per message', '')
            }
        }).catch(error => {
            error.message = _.get(error, 'response.data.message', error.message)
            const allow_cascade = _.get(error.response.data.errors, 'cascade.allow_cascade', false)
            // delete text message if get any error while send new text message
            if (data.type === 'text') {
                dispatch(deleteMessageAction(data._id, data.userId))
            }
            if (allow_cascade === false) {
                dispatch(setSweetAlert({ description: `Could not send Message: ${error.message}` }))
                return callback(true)
            }
            dispatch(setSweetAlert({ description: 'Could not send Message: Problem in card authorization please re-enter your card details or add new card.' }))
            callback(false)
        })
}

// Get all tips messages
export const getAllTipsMessages = (data) => dispatch => {
    dispatch(setLoading())
    axios.post(BASE_URL + '/api/chat/get_tips_list', data)
        .then(res => {
            dispatch(setTipsList(res.data))
        })
}

export const setAllowUserToChat = (data, callback) => () => {
    axios.post(BASE_URL + '/api/chat/update_allow_user_to_chat', data)
        .then(() => {
            callback()
        })
}

export const getUserSendMessageStatus = (data, callback) => () => {
    axios.post(BASE_URL + '/api/chat/get_user_send_message_status', data)
        .then(res => {
            callback(res)
        })
}

// Get single message to update
export const getSingleMessage = (data) => dispatch => {
    const messageId = _.get(data, 'messageId', false)
    if (messageId === false) {
        return dispatch(paymentResponseRecieved())
    }

    axios.get(BASE_URL + `/api/chat/get_chat_message?id=${data.messageId}`)
        .then(res => {
            dispatch(updateMessageAction(res.data.data))
            dispatch(paymentResponseRecieved())
        })
}

export const sendMassMessage = (data) => async () => {
    try {
        await axios.post(BASE_URL + '/api/message/send-mass-message', data)
        return true
    } catch (error) {
        error.message = _.get(error, 'response.data.message', error.message)
        alert(`Could not send Message: ${error.message}`)
        return false
    }
}

export const setWelcomeMessage = (data) => async () => {
    try {
        await axios.post(BASE_URL + '/api/message/set-welcome-message', data)
        return true
    } catch (error) {
        error.message = _.get(error, 'response.data.message', error.message)
        alert(`Could not set Message: ${error.message}`)
        return false
    }
}

export const sendMessage = (data) => async () => {
    try {
        const res = await axios.post(BASE_URL + '/api/message/send-message', data)
        return res.data.data
    } catch (error) {
        const res = { isError: true, error }
        return res
    }
}

export const getErrorMessage = () => async () => {
    try {
        const res = await axios.get(BASE_URL + '/admin/get-error-mass-messages')
        return res.data.data
    } catch (error) {
        const res = { isError: true, error }
        return res
    }
}

export const setLoading = () => {
    return {
        type: IS_LOADING
    }
}

export const setIsMoreMessagesLoading = (isLoading, user_id) => {
    return {
        type: IS_MORE_MESSAGE_LOADING,
        payload: { isLoading, user_id }
    }
}

export const setUserList = (data) => {
    return {
        type: CHAT_USER_LIST,
        payload: data
    }
}

export const updateUserList = (data) => {
    return {
        type: UPDATE_USER_DETAILS,
        payload: data
    }
}

export const setMessages = (data, user_id) => {
    return {
        type: SET_MESSAGES,
        payload: { data, user_id }
    }
}

export const setMoreMessages = (data, user_id) => {
    return {
        type: LOAD_MORE_MESSAGE,
        payload: { data, user_id }
    }
}

export const setTipsList = (data) => {
    return {
        type: TIPS_LIST,
        payload: data
    }
}

export const chatUserProfileInfo = (data) => {
    return {
        type: CHAT_USER_PROFILE_INFO,
        payload: data
    }
}

export const updateUsersNickname = (data) => {
    return {
        type: UPDATE_USER_NICKNAME,
        payload: data
    }
}

export const setUnreadCount = (data) => {
    return {
        type: SET_UNREAD_COUNT,
        payload: data
    }
}

export const setSelectedChatId = (id, history) => dispatch => {
    const state = store.getState()

    if (state.auth.isAdmin === true) {
        dispatch(setSelectedChatModelId(state.auth.appSettings.model_id))
        dispatch(setChatId(id))
        history.push(`/admin/chat/${id}`)
    } else {
        dispatch(setChatId(id))
        history.push('/private-chat')
    }
}

export const setSelectedUserId = (id, history) => dispatch => {
    const { chat } = store.getState()
    if (history) {
        dispatch(setChatId(id))
        dispatch(setIsChatScreenOpen(true))
        history.push(`/admin/chat/${id}`)
    }
    if (chat.userInfo && !chat.userProfileInfo[id]) {
        dispatch(chatUserProfileInfoAction(id))
    }
}

export const setIsChatScreenOpen = isOpen => dispatch => {
    dispatch({
        type: SET_IS_CHAT_SCREEN_OPEN,
        payload: isOpen
    })
}

export const setChatId = (id) => {
    return {
        type: SET_CHAT_SELECTED_ID,
        payload: id
    }
}

export const setSelectedChatModelId = (id) => {
    return {
        type: SET_CHAT_MODEL_ID,
        payload: id
    }
}

export const addNewMessageInEnd = (data, user_id) => {
    return {
        type: ADD_NEW_MESSAGE_IN_END,
        payload: { data, user_id }
    }
}

export const updateMessageAction = (data, user_id, sendingId = '') => {
    return {
        type: UPDATE_MESSAGE,
        payload: { data, user_id, sendingId }
    }
}

export const deleteMessageAction = (id, user_id) => {
    return {
        type: DELETE_MESSAGE,
        payload: { id, user_id }
    }
}

export const toggleChatTipPopup = data => {
    return {
        type: TOGGLE_CHAT_TIP_POPUP,
        payload: data
    }
}

// Block/Unblock User in v2
export const blockUserAndCancelUserSubscription = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/v2/admin/block-user', data)
        .then(res => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            dispatch(removeConfirmSweetAlert())
        }).catch(error => {
            const message = _.get(error, 'response.data.message', 'There was a problem in Block user.')
            dispatch(removeConfirmSweetAlert())
            const payload = { description: message }
            dispatch(setSweetAlert(payload))
            return callback()
        })
}

export const blockUserAndCancelUserSubscriptionAsync = (data) => async (dispatch) => {
    try {
        const res = await axios.post(BASE_URL + '/api/v2/admin/block-user', data)
        const payload = {
            description: res.data.message
        }
        dispatch(setSweetAlert(payload))
        dispatch(removeConfirmSweetAlert())
        return { success: 1 }
    } catch (err) {
        const message = _.get(err, 'response.data.message', 'There was a problem in Block user.')
        dispatch(removeConfirmSweetAlert())
        const payload = { description: message }
        dispatch(setSweetAlert(payload))
        return { success: 0 }
    }
}

export const updateUserLastSeen = data => {
    return {
        type: UPDATE_USER_LAST_SEEN,
        payload: data
    }
}

export const updateUserAmountSpent = data => {
    return {
        type: UPDATE_USER_AMOUNT_SPENT,
        payload: data
    }
}

export const updateIsMessagesLoading = data => {
    return {
        type: IS_MESSAGES_LOADING,
        payload: data
    }
}

export const updateReadMessageCount = data => {
    return {
        type: IS_READ_MESSAGE_COUNT,
        payload: data
    }
}

export const showBootstrapNavbar = status => {
    return {
        type: SHOW_BOOTSTRAP_NAVBAR,
        payload: status
    }
}

/*
    Rotate Image
*/
export const rotateImage = (data) => async (dispatch) => {
    dispatch(setBlurLoading())
    const url = `${BASE_URL}/api/upload/rotate_image`
    try {
        const res = await axios.post(url, data)
        const message = res.data.message
        dispatch(setSweetAlert({ description: message }))
        dispatch(stopBlurLoading())
        if (data.type === 'message') {
            let payloadData = {
                data: res.data.data.response,
                user_id: data.userId
            }
            let action = SET_ROTATE_CHAT_IMAGE
            if (res.data.data.response.isMassMessage === true) {
                action = SET_ROTATE_CHAT_MASS_MESSAGE
                payloadData = {
                    udid: res.data.data.response.udid,
                    data: res.data.data.response
                }
            }
            dispatch({ type: action, payload: payloadData })
        }
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'There was an error while rotating the image.')
        dispatch(setSweetAlert({ description: errorMessage }))
        dispatch(stopBlurLoading())
    }
}

/*
    Unlock message content for user
*/
export const unlockMessageContentForUser = (data) => async (dispatch) => {
    dispatch(setBlurLoading())
    try {
        const res = await axios.post(BASE_URL + '/api/chat/unlock_message_from_user', data)
        const message = res.data.message
        dispatch(setSweetAlert({ description: message }))
        dispatch(stopBlurLoading())
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'There was an error while unlocking content for user.')
        dispatch(setSweetAlert({ description: errorMessage }))
        dispatch(stopBlurLoading())
    }
}

/*
    Delete message from subscribed user
*/
export const deleteMessageFromSubscriber = (data) => async (dispatch) => {
    dispatch(setBlurLoading())
    try {
        const res = await axios.post(BASE_URL + '/api/chat/delete_message_from_user', data)
        const message = res.data.message
        dispatch(setSweetAlert({ description: message }))
        dispatch(stopBlurLoading())
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'There was an error while deleting message from subscriber.')
        dispatch(setSweetAlert({ description: errorMessage }))
        dispatch(stopBlurLoading())
    }
}

/*
    Delete message from all users
*/
export const deleteMessageFromAll = (data) => async (dispatch) => {
    dispatch(setBlurLoading())
    try {
        const res = await axios.post(BASE_URL + '/api/chat/delete_message_from_all', data)
        const message = res.data.message
        dispatch(setSweetAlert({ description: message }))
        dispatch(stopBlurLoading())
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'There was an error while deleting message from all users.')
        dispatch(setSweetAlert({ description: errorMessage }))
        dispatch(stopBlurLoading())
    }
}

/*
    Refresh blur url of image
*/
export const refreshBlurImage = (data) => async (dispatch) => {
    dispatch(setBlurLoading())
    try {
        const res = await axios.post(`${BASE_URL}/api/upload/refresh_blur_picture`, data)
        if (res.data.updated === true) {
            const message = 'Blur image refreshed.'
            dispatch(setSweetAlert({ description: message }))
        }
        if (res.data.updated === false) {
            const message = 'There was an error while refreshing blur image.'
            dispatch(setSweetAlert({ description: message }))
        }
        dispatch(stopBlurLoading())
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'There was an error while refreshing blur image.')
        dispatch(setSweetAlert({ description: errorMessage }))
        dispatch(stopBlurLoading())
    }
}

/*
    Send video processing request
*/
export const sendVideoProcessingRequest = (data) => async (dispatch) => {
    dispatch(setBlurLoading())
    try {
        await axios.post(BASE_URL + '/api/admin/send_video_process_request', data)
        const message = 'Video processing request sent.'
        dispatch(setSweetAlert({ description: message }))
        dispatch(stopBlurLoading())
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'There was an error while sending video processing request.')
        dispatch(setSweetAlert({ description: errorMessage }))
        dispatch(stopBlurLoading())
    }
}

/*
    Update users last message
*/
export const updateUsersLastMessage = data => dispatch => {
    const { message } = data
    if (message && message.includes('<user_name>')) {
        const { chat } = store.getState()
        const { user_id } = data
        const { name } = chat.userProfileInfo[user_id]
        data.message = message.replace('<user_name>', name)
    }
    dispatch({
        type: UPDATE_USER_LAST_MESSAGE,
        payload: data
    })
}

export const setSearchInputValue = data => dispatch => {
    dispatch({
        type: SET_SEARCH_INPUT_VALUE,
        payload: data
    })
}

export const deleteAllMessageAction = data => dispatch => {
    dispatch({
        type: DELETE_MESSAGE_FROM_ALL,
        payload: data
    })
}

// delete all locked mass message while resend mass message
export const deleteResendMassMessage = data => dispatch => {
    dispatch({
        type: DELETE_RESEND_MASS_MESSAGE,
        payload: data
    })
}

// delete mass message from current user chat while resend mass message
export const deleteSingleResendMassMessage = (userId, udid) => dispatch => {
    dispatch({
        type: DELETE_SINGLE_RESEND_MASS_MESSAGE,
        payload: { userId, udid }
    })
}

